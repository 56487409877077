.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("./login.png") no-repeat;
  background-color: #565656;
  position: relative;
  background-size: cover;
  height: 100vh;
  background-position: 50%;
}

.content {
  margin: 0 auto;
  width: 1000px;
  -webkit-box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
  box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.loginQR {
  width: 300px;
  margin: 0 auto;
  background-color: white;
  text-align: center;
  margin-bottom: 220px;
  z-index: 99;
}

.loginIphone {
  padding: 30px;
  width: 300px;
  margin: 0 auto;
  background-color: #ffffff00;
  text-align: right;
  margin-bottom: 220px;
  z-index: 99;
}

.textQR {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  bottom: 10px;
}

.particles-bg-canvas-self {
  z-index: 1 !important;
}
.ant-form-item-required {
  color: white !important;
}
