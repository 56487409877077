
.uploading-panel{
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    position: relative;
    min-height: 130px;
    align-items: center;
    padding: 10px;
    max-height: 800px;
    min-height: 300px;
    overflow-y: auto;
}
.chooseFile{
    float: left;
    margin: 15px;
    color: black;
}
.right{
    float: right;
    margin: 10px;
}
.margin-r-10{
    margin-right: 10px;
}
.complete{
    font-size: 16px;
    color: black;
    margin-top: 20px;
}
.buttonUpload{
    float: right;
    position: relative;
    bottom: 5px;
}